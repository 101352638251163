import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"


import ImgPagaleATusAmigos from "../images/productos/pagale-a-tus-amigos.gif"
import ImgEnviaPideDinero from "../images/productos/envia-pide-dinero.gif"
import ImgDivideLaCuenta from "../images/productos/divide-la-cuenta.gif"


export const StyledText = styled.div`
  display: inline-block;
  max-width: 32rem;
`

export const StyledHeading = styled.h2`
  line-height: 1.125em;
  letter-spacing: -0.025em;
  color: rgb(25, 28, 31);
  font-size: 2.5rem;
  margin: 0px;

  @media (min-width: 1081px) {
    font-size: 3rem;
  }
`

export const StyledImage = styled.div`
  @media (max-width: 1080px) {
    margin-top: 6rem;
    max-height: 32rem;
  }
`

export const StyledAnimation = styled.div`
  margin: 0px auto;
  box-sizing: border-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const StyledSlide = styled.div`
  padding: 0px 3rem;
  margin: auto;
  max-width: 86rem;
  min-height: 100vh;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 519px) {
    padding: 0px 1.5rem;
    margin: 8.25rem 0px 12rem;
    height: auto;
    min-height: 0px;
    max-height: none;
  }

  @media (max-width: 1080px) {
    margin: 70px 0px;
    flex-direction: column-reverse;
    -moz-box-pack: center !important;
    justify-content: center !important;
    -moz-box-align: center !important;
    align-items: center !important;
    max-height: none;

    &:first-child {
      margin-top: auto;
    }

    & > div:first-child {
      margin-right: 0px;
    }

    & h2,
    & p {
      text-align: center;
    }
  }

  @media (min-width: 1081px) {
    ${ props => props.reverse ? "flex-direction: row-reverse" : "" };

    & ${StyledText} {
      ${ props => props.reverse ? "" : "margin-right: 4rem" };
    }

    & ${StyledImage} {
      ${ props => props.reverse ? "margin-right: 4rem" : "" };
    }
  }
`


const MisAmigosPage = () => (
  <Layout>
    <SEO title="Mis amigos" />
    <StyledSlide>
      <StyledText>
        <StyledHeading>Divide la cuenta</StyledHeading>
        <p>
          Pagar la cuenta en un grupo siempre es complicado.<br />
          Con KKO puedes dividir la cuenta de manera muy sencilla...<br />
          ¡Cuentas claras, amistades largas!
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgDivideLaCuenta} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide reverse={true}>
      <StyledText>
        <StyledHeading>Págale a tus amigos al instante</StyledHeading>
        <p>
          Cuando envíes dinero a tus amigos KKO, lo recibirán de inmediato y ambos recibirán una notificación.<br />
          <br />
          ¡Olvídate de las tradicionales fotos de recibo!
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgPagaleATusAmigos} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
    <StyledSlide>
      <StyledText>
        <StyledHeading>Envía/Pide dinero</StyledHeading>
        <p>
          ¿Tu amigo no te pago el boleto para el estadio?<br />
          Pídeselo por KKO, sin importar cual sea su banco.
        </p>
      </StyledText>
      <StyledImage>
        <StyledAnimation>
          <img src={ImgEnviaPideDinero} alt="" />
        </StyledAnimation>
      </StyledImage>
    </StyledSlide>
  </Layout>
)

export default MisAmigosPage
